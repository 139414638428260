import React from "react"
import LayoutSite from "../components/layouts/layoutSite"
import Title from "../components/crumbs/title"
import PageSeo from "../components/crumbs/pageSeo"
import MoreArticles from "../components/crumbs/moreArticles"
import { useStaticQuery, graphql } from "gatsby"
import CategoryTopNews from "../components/crumbs/categoryTopNews"
import Ads from "../components/ads"
import Helmet from "react-helmet"
import BoxAd from "../components/crumbs/boxAd"

export default function Home() {
  const { allStrapiArticle } = useStaticQuery(graphql`
    query {
      allStrapiArticle(limit: 32, sort: {order: DESC, fields: publish}, filter: {status: {eq: "live"}, category: {name: {eq: "Kultúra"}}}) {
        edges {
          node {
              title
              category {
                  name
              }
              cover {
                  childImageSharp {
                      fluid {
                          ...GatsbyImageSharpFluid_withWebp
                      }
                  }
              }
              excerpt
          }
        }
      }
    }
  `)

  const articlesTopNews = allStrapiArticle.edges.map(_ => _.node)

  return (
    <>
      {/*<Helmet><script async src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script></Helmet>*/}
      <PageSeo
        title="Minden Percben | Kultúra"
        description="Minden amiről tudnia érdemes a kultúra világából. Mert minden perc érdekes!"
        keywords="kultúra, művészet, rendezvény, kiállítás, gasztronómia"
        canonical="https://www.mindenpercben.hu/kultura"
        canonicalMobile = "https://m.mindenpercben.hu/kultura"
      />
      <LayoutSite>
        <Title category="Kultúra" />

        <CategoryTopNews articles={articlesTopNews} />

        <BoxAd isBlock={true} ad={ <iframe src="https://mindenpercben.hu/r/1213172.html" width="1100" height="320" frameborder="0" scrolling="no"></iframe> } />
        
        <MoreArticles slug="/kereses/kultura/1" text="A Kultúra rovat további cikkei" />
      </LayoutSite>
    </>
  )
}